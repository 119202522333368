import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import { findNodeHandle, Linking, SectionList, StyleSheet, Text, UIManager, View } from "react-native"
import styled from "styled-components"
import {
	ApartmentSource,
	APIS,
	GetNewsResponse,
	GetSearchV2Response,
	Legacy,
	SEARCH_SERVICE_TYPE,
	ServiceType,
} from "@zigbang/api"

import { sendEvent, ZB_EVENT_TYPE } from "@zigbang/bridge/pinpoint"
import { NavigationStackScreenProps } from "@zigbang/screens/@types/navigation/stack"
import { ListItemCData } from "@zigbang/screens/components/home/ListItemC"
import { TotalSearchItem } from "@zigbang/screens/lib/CommonSearch"
import { LayoutOptions } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { sentry } from "@zigbang/screens/lib/sentry"
import { SelectedMarker, SelectedMarkerType } from "@zigbang/screens/lib/WebMapStore"
import useUserInfoStore from "@zigbang/screens/lib/Zustand/store/userInfoStore"
import { OfficetelRouterName } from "@zigbang/screens/routes/officetel/types"
import { Color, FastImage, TextBSmall, TextXSmall, Touchable } from "@zigbang/zuix"
import { Icon } from "@zigbang/zuix2"
import { Highlighter } from "../lib/highlighter"
import { mapSubMenuStatus } from "../src/components/config"

@observer
export class GatewayScreen extends React.Component<HomeScreenProps, TotalSearchScreenState> {
	static layoutOptions: LayoutOptions = {
		fixedHeader: true,
	}

	static async getInitialProps() {
		try {
			const apis = new NewsLoader()
			const [newsItems, noticeItems, banner] = await Promise.all([
				apis.load("news"),
				apis.load("notice"),
				this.getBanner(),
			])

			return {
				newsItems,
				noticeItems,
				banner,
			}
		} catch (e) {
			return {
				newsItems: [],
				noticeItems: [],
				banner: {
					imgUrl: "",
					linkUrl: "",
				},
			}
		}
	}

	// www메인배너
	static getBanner = async () => {
		try {
			const response = await fetch(`${process.env.STATIC_HOST}/wwwbanner/banner.json`)
			const banner = await response.json()

			return {
				imgUrl: banner.background.url,
				linkUrl: banner.link,
			}
		} catch (e) {
			sentry.notify(new Error(`www banner${e}`))

			return {
				imgUrl: "",
				linkUrl: "",
			}
		}
	}

	@observable
	modelListData: {
		newsItems: ListItemCData[]
		noticeItems: ListItemCData[]
	} = {
		newsItems: [],
		noticeItems: [],
	}
	keyword: string = ""

	private searchAPI: APIS.Search = new APIS.Search({ endpoint: process.env.APIS_HOST })

	private readonly 키코드_방향키_위 = 38
	private readonly 키코드_방향키_아래 = 40
	private readonly 키코드_방향키_엔터 = 13

	private rootRef: React.RefObject<View> = React.createRef<View>()
	private inputRef: React.RefObject<TotalSearchInput> = React.createRef<TotalSearchInput>()
	private buttons = [
		{
			type: ServiceType.아파트,
			title: "아파트",
			subtitle: "(매매/전월세/신축분양)",
			placeholder: "원하시는 지역명, 지하철역, 단지명(아파트명)을 입력해주세요",
			img: "",
		},
		{
			type: ServiceType.빌라,
			title: "빌라,투룸+",
			placeholder: "원하시는 지역명, 지하철역을 입력해주세요",
			img: "",
		},
		{
			type: ServiceType.원룸,
			title: "원룸",
			placeholder: "원하시는 지역명, 지하철역을 입력해주세요",
		},
		{
			type: ServiceType.오피스텔,
			title: "오피스텔",
			placeholder: "원하시는 지역명, 지하철역, 오피스텔명을 입력해주세요",
		},
		{
			type: ServiceType.상가,
			title: "상가",
			placeholder: "원하시는 지역명, 지하철역을 입력해주세요",
		},
	]

	constructor(props: any) {
		super(props)

		this.state = {
			focusedIndex: 0,
			isFocused: false,
			isSearching: false,
			query: "",
			serviceType: "아파트",
			selectedIndex: 0,
			searchResults: [],
			resultAreaLocation: {
				x: 0,
				y: 0,
			},
		}

		this.modelListData.newsItems = props.newsItems
		this.modelListData.noticeItems = props.noticeItems

		this.통합검색_서버통신 = this.통합검색_서버통신.bind(this)
		this.onKeyDownHandler = this.onKeyDownHandler.bind(this)
		this.onFocus = this.onFocus.bind(this)
		this.통합검색_우측버튼_클릭 = this.통합검색_우측버튼_클릭.bind(this)
	}

	componentDidMount() {
		const { newsItems, noticeItems } = this.modelListData

		if (!newsItems.length || !noticeItems.length) {
			this.reloadNews()
		}
		this.setResultAreaLocation()
		window.addEventListener("resize", this.setResultAreaLocation.bind(this))

		sendEvent(ZB_EVENT_TYPE.ZB_SCREEN_VIEW, { screen_name: "메인" })
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setResultAreaLocation.bind(this))
	}

	render() {
		return (
			<Root ref={this.rootRef}>
				{this.render메인상단그림영역()}
				{this.render통합검색결과노출영역()}
				{this.render중앙안내영역()}
				{this.render앱스토어홍보영역()}
			</Root>
		)
	}

	onSelectMainSearchTab(title: string, index: number) {
		let serviceType = ""
		switch (title) {
			case "아파트":
				serviceType = "아파트"
				break
			case "빌라,투룸+":
				serviceType = "빌라"
				break
			case "원룸":
				serviceType = "원룸"
				break
			case "오피스텔":
				serviceType = "오피스텔"
				break
			case "상가":
				serviceType = "상가"
				break
			// no default
		}
		this.setState({ selectedIndex: index, query: "", serviceType })
	}

	extractHttpUrlFromDeeplinkOrReturnOriginal(url: string): string {
		if (typeof url !== "string" || (!url.startsWith("zb://") && !url.startsWith("zigbang://"))) {
			return url
		}

		const regex = /http(.+)&?/
		const matchedRegexUrl = url.match(regex)

		if (matchedRegexUrl && matchedRegexUrl.length) {
			const urlInDeeplink = matchedRegexUrl[0].split("&")[0]
			return decodeURIComponent(urlInDeeplink)
		}

		return url
	}

	getAdInquiryUrl() {
		if (process.env.STAGE === "dev") {
			return "https://dev.hogangnono.com/ad?from=zigbang"
		} else if (process.env.STAGE === "preview") {
			return "https://beta.hogangnono.com/ad?from=zigbang"
		}
		return "https://hogangnono.com/ad?from=zigbang"
	}

	getCeoUrl = () => {
		if (process.env.STAGE === "dev") {
			return "https://ceo.zigbang.net"
		} else if (process.env.STAGE === "preview") {
			return "https://ceo-preview.zigbang.net"
		}
		return "https://ceo.zigbang.com"
	}

	render메인상단그림영역() {
		return (
			<메인_상단_그림영역.메인_이미지_배경>
				<메인_상단_그림영역.메인_이미지_문구_영역>
					<메인_상단_그림영역.메인_이미지_문구>어떤 집을 찾고 계세요?</메인_상단_그림영역.메인_이미지_문구>
				</메인_상단_그림영역.메인_이미지_문구_영역>
				<메인_상단_그림영역.하단_서비스_검색_영역>
					<서비스_선택영역.전체영역>
						<서비스_선택영역.선택버튼_전체영역>
							<서비스_선택영역.선택_애니매이션 selected={this.state.selectedIndex} />
							{this.buttons.map(({ title }, index) => {
								const isActive = this.state.selectedIndex === index
								const props = { active: isActive.toString() }
								return (
									<서비스_선택영역.선택버튼_개별영역 key={index}>
										<서비스_선택영역.선택버튼_제목
											onPress={() => this.onSelectMainSearchTab(title, index)}
											{...props}>
											{title}
										</서비스_선택영역.선택버튼_제목>
									</서비스_선택영역.선택버튼_개별영역>
								)
							})}
						</서비스_선택영역.선택버튼_전체영역>
						<서비스_선택영역.통합검색_영역>
							<TotalSearchInput
								ref={this.inputRef}
								placeholder={this.buttons[this.state.selectedIndex].placeholder}
								query={this.state.query}
								onChangeInput={(value: string) => {
									this.setState({ query: value, isFocused: true })
								}}
								searchingWithQuery={this.통합검색_서버통신}
								onKeyDown={this.onKeyDownHandler}
								onFocus={this.onFocus}
								onBlur={() => {
									//
								}}
							/>
							<통합검색_찾아보기_버튼 onPress={this.통합검색_우측버튼_클릭}>
								<통합검색_찾아보기_버튼_이미지
									src={`${process.env.S_HOST}/v1/web/main/ic_search.png`}
									alt="찾아보기"
								/>
							</통합검색_찾아보기_버튼>
						</서비스_선택영역.통합검색_영역>
					</서비스_선택영역.전체영역>
				</메인_상단_그림영역.하단_서비스_검색_영역>
			</메인_상단_그림영역.메인_이미지_배경>
		)
	}

	render통합검색결과노출영역() {
		return (
			<TotalSearchResults
				options={this.state.searchResults}
				query={this.state.query}
				focusedIndex={this.state.focusedIndex}
				isSearching={this.state.isSearching}
				onSelect={this.통합검색_결과물_선택}
				isFocused={this.state.isFocused}
				resultAreaLocation={this.state.resultAreaLocation}
			/>
		)
	}

	render중앙안내영역() {
		const { banner } = this.props
		return (
			<중앙_안내_영역.전체영역>
				<중앙_안내_영역.가운데영역>
					<중앙_안내_영역.광고배너>
						<View style={introSectionStyle.sectionCard}>
							<TextBSmall b color={Color.black} style={{ fontSize: 16 }}>
								소개할게요
							</TextBSmall>
						</View>
						<Touchable
							style={{ marginTop: 20 }}
							onPress={() => {
								window.open(banner.linkUrl, "_blank")
							}}>
							<FastImage style={{ width: 260, height: 140 }} source={{ uri: banner.imgUrl }} />
						</Touchable>
					</중앙_안내_영역.광고배너>

					<중앙_안내_영역.뉴스영역>
						<View style={introSectionStyle.sectionCard}>
							<TextBSmall b color={Color.black} style={{ fontSize: 16 }}>
								뉴스
							</TextBSmall>
						</View>
						<Touchable
							style={introSectionStyle.button}
							onPress={() => {
								window.open("/event/news", "_blank")
							}}>
							<중앙_안내_영역.더보기_영역>
								<중앙_안내_영역.더보기_버튼>더보기</중앙_안내_영역.더보기_버튼>
							</중앙_안내_영역.더보기_영역>
						</Touchable>
						<SectionList
							style={{ marginTop: 8 }}
							keyExtractor={(item) => `${item.id}`}
							sections={[{ data: this.modelListData.newsItems.slice() }]}
							renderItem={this.renderNewsItem}
						/>
					</중앙_안내_영역.뉴스영역>

					<중앙_안내_영역.공지영역>
						<View style={introSectionStyle.sectionCard}>
							<TextBSmall b color={Color.black} style={{ fontSize: 16 }}>
								공지사항
							</TextBSmall>
						</View>
						<Touchable
							style={introSectionStyle.button}
							onPress={() => {
								window.open("/event/notice", "_blank")
							}}>
							<중앙_안내_영역.더보기_영역>
								<중앙_안내_영역.더보기_버튼>더보기</중앙_안내_영역.더보기_버튼>
							</중앙_안내_영역.더보기_영역>
						</Touchable>
						<SectionList
							style={{ marginTop: 8 }}
							keyExtractor={(item) => `${item.id}`}
							sections={[{ data: this.modelListData.noticeItems.slice() }]}
							renderItem={this.renderItem}
						/>
					</중앙_안내_영역.공지영역>
					<중앙_안내_영역.advertisement>
						<View style={introSectionStyle.sectionCard}>
							<TextBSmall b color={Color.black} style={{ fontSize: 16 }}>
								광고·제휴
							</TextBSmall>
						</View>
						<View style={{ marginTop: 8 }}>
							<중앙_안내_영역.목록_한개씩_레이아웃
								style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
								onPress={() => {
									window.open(this.getAdInquiryUrl(), "_blank")
								}}>
								<TextXSmall>
									{{ value: "분양 광고 및 일반 광고 문의", color: Color.black, maxLine: 1 }}
								</TextXSmall>
								<Icon shape="ArrowMore" width={14} height={14} color="#4F4F4F" />
							</중앙_안내_영역.목록_한개씩_레이아웃>
							<중앙_안내_영역.목록_한개씩_레이아웃
								style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
								onPress={() => {
									window.open(this.getCeoUrl(), "_blank")
								}}>
								<TextXSmall>
									{{ value: "중개사무소 가입 및 상품 문의", color: Color.black, maxLine: 1 }}
								</TextXSmall>
								<Icon shape="ArrowMore" width={14} height={14} color="#4F4F4F" />
							</중앙_안내_영역.목록_한개씩_레이아웃>
						</View>
					</중앙_안내_영역.advertisement>
				</중앙_안내_영역.가운데영역>
			</중앙_안내_영역.전체영역>
		)
	}

	renderItem = (data: { item: ListItemCData }) => {
		const { item } = data
		const url = item?.mobileLink && this.extractHttpUrlFromDeeplinkOrReturnOriginal(item.mobileLink)

		return (
			<중앙_안내_영역.목록_한개씩_레이아웃
				onPress={() => {
					window.open(url, "_blank")
				}}>
				<TextXSmall>{{ value: item.title, color: Color.black, maxLine: 1 }}</TextXSmall>
			</중앙_안내_영역.목록_한개씩_레이아웃>
		)
	}

	renderNewsItem = (data: { item: ListItemCData }) => {
		const { item } = data
		const url = item?.mobileLink && this.extractHttpUrlFromDeeplinkOrReturnOriginal(item.mobileLink)

		return (
			<중앙_안내_영역.목록_한개씩_레이아웃
				onPress={() => {
					sendEvent(ZB_EVENT_TYPE.SELECT_CONTENT, {
						screen_name: "메인",
						content_type: "뉴스",
						item_name: item.title,
						item_id: url,
					})

					window.open(url, "_blank")
				}}>
				<TextXSmall>{{ value: item.title, color: Color.black, maxLine: 1 }}</TextXSmall>
			</중앙_안내_영역.목록_한개씩_레이아웃>
		)
	}

	render앱스토어홍보영역() {
		return (
			<View style={{ backgroundColor: "#F3F5F5", height: 279 }}>
				<앱스토어_이동_영역.배경영역>
					<BaseText
						style={{ fontSize: 17, marginBottom: 40, marginTop: 101, marginLeft: 4, fontWeight: "bold" }}>
						직방앱을 다운받으세요!
					</BaseText>
					<앱스토어_이동_영역.연결_버튼_영역>
						<Touchable
							style={{
								width: 190,
								height: 52,
								// @ts-ignore
								backgroundImage: `url(${`${process.env.S_HOST}/legacy/images/v2/main/BTN_download_android_nor.png`})`,
								backgroundSize: "contain",
							}}
							onPress={() => {
								Linking.openURL("http://goo.gl/W5Mv8S")
							}}>
							<앱스토어_이동_영역.안드로이드_바로가기 />
						</Touchable>

						<Touchable
							style={{
								width: 190,
								height: 52,
								// @ts-ignore
								backgroundImage: `url(${`${process.env.S_HOST}/legacy/images/v2/main/BTN_download_ios_nor.png`})`,
								backgroundSize: "contain",
							}}
							onPress={() => {
								Linking.openURL("http://goo.gl/w0UeVw")
							}}>
							<앱스토어_이동_영역.아이폰_바로가기 />
						</Touchable>
					</앱스토어_이동_영역.연결_버튼_영역>
				</앱스토어_이동_영역.배경영역>
			</View>
		)
	}

	통합검색_우측버튼_클릭() {
		if (!this.state.searchResults || this.state.searchResults.length < 1) {
			return
		}
		this.통합검색_결과물_선택(0)
	}

	통합검색_결과물_선택 = async (selectedIdx: number) => {
		const positiveSelectedIdx = Math.max(0, selectedIdx)
		try {
			const { type, id, data, label } = this.state.searchResults[positiveSelectedIdx]
			let isOffer = false
			let markerType: SelectedMarkerType

			switch (type) {
				case "apartment":
					markerType = SelectedMarkerType.APT
					isOffer = !(data._source as ApartmentSource).서비스구분.match("입주2년")
					break
				case "subway":
					markerType = SelectedMarkerType.SUBWAY
					break
				case "school":
					markerType = SelectedMarkerType.SCHOOL
					break
				case "officetel":
					markerType = SelectedMarkerType.OFFICETEL
					break
				case "address":
					markerType = SelectedMarkerType.LOCAL
					break
				case "store":
					markerType = SelectedMarkerType.STORE
					break
				default:
					throw new Error("invalid marker type!")
			}

			const setValue = {
				id,
				latitude: data.lat,
				longitude: data.lng,
				_data: {
					zoom: data.zoom,
					zoom_level: data.zoom_level,
					zoom_level_v2: data.zoom_level_v2,
				},
			}

			if (isOffer) {
				Object.assign(setValue._data, { isOffer })
			}

			if (markerType !== SelectedMarkerType.LOCAL) {
				Object.assign(setValue, { markerType })
			}

			// 아래 이슈를 해결하기 위해 "통합검색 후 이동하기" 전에 local storage에 있는 MAP_TYPE 값을 지워준다.
			// 1. 빌라를 보고 있음 (MAP_TYPE === 'villa').
			// 2. Index로 이동 후 아파트 검색.
			// 3. 매매전월세 마커가 떠야하지만 빌라 마커가 뜸.
			mapSubMenuStatus.clear()

			this.통합검색_후_이동하기(markerType, setValue)

			this.setState({
				focusedIndex: -1,
				// searchResults: [],
				isSearching: false,
				query: label,
				isFocused: false,
			})
		} catch (e) {
			//
		}
	}

	onFocus() {
		this.setState({ isFocused: true })
		this.setResultAreaLocation()
	}

	setResultAreaLocation() {
		const 전체_문서영역 = findNodeHandle(this.rootRef.current)
		const 기준이되는_컴포넌트 = findNodeHandle(this.inputRef.current)
		if (!기준이되는_컴포넌트 || !전체_문서영역) {
			return
		}
		UIManager.measureLayout(
			기준이되는_컴포넌트,
			전체_문서영역,
			() => {
				//
			},
			(x, y, width, height) => {
				this.setState({ resultAreaLocation: { x, y: y + height } })
			}
		)
	}

	서비스별_문자경로(서비스타입: ServiceType) {
		if (서비스타입 === ServiceType.아파트) {
			return "Apt"
		} else if (서비스타입 === ServiceType.빌라) {
			return "Villa"
		} else if (서비스타입 === ServiceType.오피스텔) {
			return "Officetel"
		} else if (서비스타입 === ServiceType.원룸) {
			return "Oneroom"
		} else if (서비스타입 === ServiceType.상가) {
			return "Store"
		}
	}

	통합검색_후_이동하기(type: string | SelectedMarkerType, value: SelectedMarker) {
		try {
			const { latitude, longitude, id, _data } = value
			const { zoom_level_v2, zoom } = _data
			const convertZoom: number = zoom_level_v2 && zoom_level_v2.web ? zoom_level_v2.web : zoom
			const 서비스타입 = this.buttons[this.state.selectedIndex].type
			const 이동경로 = this.서비스별_문자경로(서비스타입)
			if (
				서비스타입 !== ServiceType.아파트 &&
				서비스타입 !== ServiceType.상가 &&
				type === SelectedMarkerType.SUBWAY
			) {
				const params = {
					type,
					ad_region_v1_id: id,
					latitude,
					longitude,
					zoom: convertZoom,
				}
				this.props.navigation.push(`Home${이동경로}SubwayItems`, params)
			} else if (서비스타입 === ServiceType.아파트 && type === SelectedMarkerType.SUBWAY) {
				this.props.navigation.push(`HomeAptSubwaysDetail`, { ad_region_v1_id: id })
			} else if (서비스타입 === ServiceType.아파트 && type === SelectedMarkerType.APT) {
				this.props.navigation.push("HomeAptDanjisDetail", { area_danji_id: id })
			} else if (서비스타입 === ServiceType.오피스텔 && type === SelectedMarkerType.OFFICETEL) {
				this.props.navigation.push(OfficetelRouterName.HomeOfficetelDanjisItems, { area_danji_id: id })
			} else {
				this.props.navigation.push(`Home${이동경로}Map`, { latitude, longitude, zoom: convertZoom })
			}
		} catch (e) {
			//
		}
	}

	async 통합검색_서버통신(query: string) {
		const { serviceType } = this.state

		try {
			if (!query || query === "") {
				throw new Error("EmptyQuery")
			}

			const { userNo, adId, os } = useUserInfoStore.getState()
			const data: GetSearchV2Response = await this.searchAPI.searchV2({
				q: query,
				serviceType: SEARCH_SERVICE_TYPE[serviceType],
				userNo,
				adId,
				os,
			})

			if (!data || (data as any).code !== "200" || !data.items) {
				throw new Error()
			}

			const items = data.items.map((v: TotalSearchItem) => ({
				id: v.id,
				label: v.name,
				type: v.type,
				desc: v.description,
				data: v,
			}))
			this.setState({ isSearching: true, searchResults: items, focusedIndex: 0 })
		} catch (e) {
			this.setState({ isSearching: false, searchResults: [], focusedIndex: 0, query: "" })
		}
	}

	onKeyDownHandler(e: { keyCode: number }) {
		if (!this.state.query || this.state.query === "") {
			return
		}

		switch (e.keyCode) {
			case this.키코드_방향키_위:
			case this.키코드_방향키_아래: {
				const updown = e.keyCode === this.키코드_방향키_위 ? -1 : e.keyCode === this.키코드_방향키_아래 ? 1 : 0
				const newIndex = Math.min(
					Math.max(0, this.state.focusedIndex + updown),
					this.state.searchResults.length - 1
				)
				this.setState({ focusedIndex: newIndex })
				break
			}
			case this.키코드_방향키_엔터:
				this.통합검색_결과물_선택(this.state.focusedIndex)
				break
			// no default
		}
	}

	async reloadNews() {
		const { newsItems, noticeItems } = this.props

		try {
			const apis = new NewsLoader()
			const response = await Promise.all([
				!newsItems.length ? apis.load("news") : newsItems.slice(),
				!noticeItems.length ? apis.load("notice") : noticeItems.slice(),
			])

			const [newsItemsFromResponse, noticeItemsFromResponse] = response

			this.modelListData.newsItems = newsItemsFromResponse
			this.modelListData.noticeItems = noticeItemsFromResponse
		} catch (e) {
			//
		}
	}
}

export default GatewayScreen

export class TotalSearchInput extends React.Component<TotalSearchInputProps> {
	inputDelay: number = 500
	timeoutId?: number // 딜레이용 timeout id
	inputElement: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>()

	componentWillReceiveProps(props: any) {
		if (this.inputElement.current && this.inputElement.current.value !== props.query) {
			this.inputElement.current.value = props.query
		}
	}

	componentDidMount() {
		if (this.props.query) {
			this.inputElement.current?.focus()
		}
	}

	onChangeInput = async (e: { target: { value: string } }) => {
		const query = e.target.value
		this.props.onChangeInput(query)
		if (this.timeoutId) {
			clearTimeout(this.timeoutId)
		}
		// 검색어 있으면 딜레이 주고 없으면 바로 실행.
		this.timeoutId = window.setTimeout(
			() => {
				this.props.searchingWithQuery(query)
			},
			query !== "" ? this.inputDelay : 0
		)
	}

	입력한_텍스트_모두_초기화 = () => {
		this.props.searchingWithQuery("")
		this.inputElement.current?.focus()
	}

	onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		if (this.props.onFocus) {
			this.props.onFocus(event)
		}
	}

	render() {
		return (
			<View style={totalSearchStyle.input}>
				<SearchInput
					type="text"
					placeholder={this.props.placeholder || ""}
					onChange={this.onChangeInput}
					onKeyDown={this.props.onKeyDown}
					autoComplete="off"
					onFocus={this.onFocus}
					ref={this.inputElement}
				/>
				{this.props.query ? (
					<Touchable style={totalSearchStyle.btnClear} onPress={this.입력한_텍스트_모두_초기화}>
						<FastImage
							style={{ width: 28, height: 28 }}
							source="//s.zigbang.com/v2/web/search/ic-delete2x.png"
						/>
					</Touchable>
				) : null}
			</View>
		)
	}
}
interface TotalSearchInputProps {
	placeholder: string
	query: string
	searchingWithQuery: (query: string) => void
	onBlur: () => void
	onKeyDown: (e: any) => void
	onChangeInput: (query: string) => void
	onFocus: (e: any) => void
}

interface TotalSearchResultProps {
	resultAreaLocation: { x: number; y: number }
	focusedIndex: number
	options: any[]
	query: string
	isSearching: boolean
	isFocused: boolean
	onSelect: (index: number) => void
}

export class TotalSearchResults extends React.PureComponent<TotalSearchResultProps, { query: string }> {
	optionElements: any[] = []
	scrollEle: any = React.createRef()
	state: { query: string } = {
		query: "",
	}

	componentWillReceiveProps(props: any) {
		if (props.query !== this.state.query) {
			this.optionElements = []
			this.setState({
				query: props.query,
			})
		}
	}

	scrollToElement = (ele: View) => {
		const handle: any = findNodeHandle(this.scrollEle)
		const target: any = findNodeHandle(ele)

		if (this.props.options.length > 0 && this.props.focusedIndex > -1) {
			const targetTop = target.offsetTop
			const targetHeight = target.scrollHeight

			const curTop = handle.scrollTop
			const scrollerHeight = handle.getBoundingClientRect().height
			if (targetTop < curTop) {
				// 스크롤 위에 있다
				handle.scrollTop = targetTop
			} else if (targetTop + targetHeight > curTop + scrollerHeight) {
				// 스크롤 아래에 있다.
				handle.scrollTop = targetTop
			} else {
				//
			}
		} else {
			//
		}
	}

	onRenderOption = (element: number) => {
		if (element === null) {
			return
		}
		this.optionElements.push(element)
	}

	render() {
		if (this.props.query === "" || !this.props.isSearching || !this.props.isFocused) {
			return null
		}
		if (this.props.options.length === 0) {
			return (
				<TotalSearchResultView
					style={{ top: this.props.resultAreaLocation.y, left: this.props.resultAreaLocation.x }}>
					<BaseText style={{ padding: 10, marginTop: 3 }}>
						검색 결과가 없습니다. 정확한 검색어를 입력해주세요.
					</BaseText>
				</TotalSearchResultView>
			)
		}
		const options: any = []
		this.props.options.forEach((v: any, i: number) => {
			options.push(
				<TotalSearchResultItem
					option={v}
					q={this.props.query}
					key={`result_${v.type}_${v.id}_${i}`}
					index={i}
					focusedIndex={this.props.focusedIndex}
					onSelect={this.props.onSelect}
					onRenderOption={this.onRenderOption}
					scrollToElement={this.scrollToElement}
				/>
			)
		})
		return (
			<TotalSearchResultView
				hasScroll={options.length > 6}
				style={{ top: this.props.resultAreaLocation.y, left: this.props.resultAreaLocation.x }}
				ref={(r) => (this.scrollEle = r)}>
				{options}
			</TotalSearchResultView>
		)
	}
}

export class TotalSearchResultItem extends React.PureComponent<any> {
	private optionElement: React.RefObject<View> = React.createRef<View>()

	getIcon(type: string) {
		if (type === "subway") {
			return require("@zigbang/screens/static/ic_subway.png")
		} else if (type === "apartment" || type === "officetel") {
			return require("@zigbang/screens/static/ic_apart.png")
		}
		return require("@zigbang/screens/static/ic_place.png")
	}

	componentWillReceiveProps(nextProps: any) {
		if (nextProps.focusedIndex === this.props.index) {
			this.props.scrollToElement(this.optionElement.current)
		}
	}

	render() {
		let grayName = ""
		let hint = this.props?.option?.data?.hint
		const { q } = this.props

		hint = (hint && ["아파트", "오피스텔"].includes(hint)) || hint.length === 0 ? "" : `(${hint})`
		grayName = this.props.option.label + hint
		return (
			<TotalSearchResultItemView ref={this.optionElement}>
				<TotalSearchSelectItemWrap
					isFocused={this.props.focusedIndex === this.props.index}
					onPress={() => this.props.onSelect(this.props.index)}>
					{this.props.option.type && (
						<FastImage
							style={{ position: "absolute", left: 13, top: 5, width: 28, height: 28 }}
							source={this.getIcon(this.props.option.type)}
						/>
					)}
					<View style={totalSearchStyle.selectItem}>
						<Highlighter
							style={{ fontFamily: "SpoqaHanSans", fontSize: 16 }}
							highlightStyle={{ fontWeight: "bold", color: "#FF681B" }}
							searchWords={[q]}
							textToHighlight={grayName}
						/>
						<View style={{ top: 2, flex: 1, flexDirection: "row-reverse" }}>
							<BaseText style={totalSearchStyle.desc}>{this.props.option.desc}</BaseText>
						</View>
					</View>
				</TotalSearchSelectItemWrap>
			</TotalSearchResultItemView>
		)
	}
}

interface TotalSearchItemOnlyMain {
	id: number
	label: string
	type: string
	desc: string
	data: TotalSearchItem
}

export interface TotalSearchScreenState {
	searchResults: TotalSearchItemOnlyMain[]
	focusedIndex: number
	isFocused: boolean
	query: string
	serviceType: string
	isSearching: boolean
	selectedIndex: number
	resultAreaLocation: {
		x: number
		y: number
	}
}

export interface HomeScreenProps extends NavigationStackScreenProps {
	newsItems: ListItemCData[]
	noticeItems: ListItemCData[]
	banner: {
		linkUrl: ""
		imgUrl: ""
	}
}

class NewsLoader {
	apis: Legacy.News

	constructor() {
		this.apis = new Legacy.News()
	}

	async load(location: any = "news") {
		const newsData: ListItemCData[] = []

		try {
			const response: GetNewsResponse = await this.apis.getNews({ location })
			if (response) {
				response.forEach((item) => {
					const data: ListItemCData = {
						title: item.title,
						mobileLink: item.link_url,
						imageUrl: item.img_url,
					}
					newsData.push(data)
				})

				return newsData
			}

			return []
		} catch (error) {
			return []
		}
	}
}

const introSectionStyle = StyleSheet.create({
	sectionCard: {
		borderBottomWidth: 1,
		borderColor: "#e1e1e1",
		paddingBottom: 11,
		paddingTop: 4,
	},
	button: {
		position: "absolute",
		right: 0,
		top: 0,
	},
})

const totalSearchStyle = StyleSheet.create({
	selectItem: {
		height: "100%",
		paddingTop: 10,
		borderBottomWidth: 1,
		borderColor: "#f6f6f6",
		borderStyle: "solid",
		flexDirection: "row",
	},
	desc: {
		color: "#757575",
		fontWeight: "normal",
		fontSize: 13,
		position: "relative",
	},
	input: {
		width: 508,
		height: 60,
		backgroundColor: "#f2f2f2",
		paddingTop: 17,
		paddingRight: 28,
		paddingBottom: 19,
		paddingLeft: 29,
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
	},
	btnClear: {
		position: "absolute",
		width: 28,
		height: 28,
		right: 10,
		top: 15,
		borderWidth: 0,
	},
})

const TotalSearchSelectItemWrap = styled(Touchable)`
	z-index: 10;
	height: 40px;
	box-sizing: border-box;
	background-color: ${(props) => (props.isFocused ? "#f6f6f6" : "#ffffff")};
	cursor: pointer;
	padding-right: 10px;
	padding-left: 52px;
	&:hover {
		background-color: #f6f6f6;
	}
`

const TotalSearchResultView = styled(View)`
	z-index: 99;
	min-height: 40px;
	max-height: 220px;
	width: 574px;
	background-color: #ffffff;
	border-style: solid;
	border-width: 1px;
	border-color: #e1e1e1;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-top-width: 0;
	overflow-y: ${(props: { hasScroll: boolean }) => (props.hasScroll ? `auto` : `hidden`)};
	position: absolute;
	margin-top: 10px;
	border-radius: 8px;
`

const TotalSearchResultItemView = styled(View)`
	margin: 1px;
	&:first-child {
		margin-top: 8px;
	}
	&:last-child {
		margin-bottom: 8px;
	}
`

const BaseText = styled(Text)`
	font-family: "SpoqaHanSans";
`

const Root = styled(View)`
	width: 100%;
	height: 100%;
	min-height: 600px;
	overflow: hidden;
	flex: 1 0 auto;
	display: flex;
`

const 메인_상단_그림영역 = {
	메인_이미지_배경: styled(View)`
		position: relative;
		width: 100%;
		height: 460px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		background-size: cover;
		background-image: url(${`${process.env.S_HOST}/v1/web/main/zigbangWeb_heroImg_1920.jpg`});
		@media (max-width: 1919px) {
			background-image: url(${`${process.env.S_HOST}/v1/web/main/zigbangWeb_heroImg_1680.jpg`});
		}
		@media (max-width: 1679px) {
			background-image: url(${`${process.env.S_HOST}/v1/web/main/zigbangWeb_heroImg_1440.jpg`});
		}
	`,
	하단_서비스_검색_영역: styled(View)`
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		flex-direction: row;
		justify-content: center;
	`,
	메인_이미지_문구_영역: styled(View)`
		width: 942px;
		height: 340px;
		padding-top: 156px;
	`,
	메인_이미지_문구: styled(BaseText)`
		width: 684px;
		height: 46px;
		text-shadow: 0 16px 40px #ffffff;
		font-size: 40px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.15;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		margin: 0 auto;
		cursor: default;
	`,
}

const 서비스_선택영역 = {
	전체영역: styled(View)`
		display: flex;
		flex-direction: column;
		width: 574px;
		height: 196px;
	`,
	선택버튼_전체영역: styled(View)`
		flex-direction: row;
		align-items: center;
		height: 40px;
		background-color: rgba(3, 20, 45, 0.85);
		border-radius: 8px;
		margin-bottom: 26px;
	`,
	선택버튼_개별영역: styled(BaseText)`
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 40px;
		padding: 0 8px;
	`,
	선택버튼_제목: styled(BaseText)`
		font-size: 17px;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		width: 100%;
		height: 100%;
		padding-top: 8px;
		z-index: 10;

		${(props: any) =>
			props.active === "true" &&
			`
			transition: 0.5s;
			color: #05372D;
			font-weight: bold;
		`}
	`,
	통합검색_영역: styled(View)`
		background-color: #ff681b;
		border-radius: 8px;
	`,
	선택_애니매이션: styled(BaseText)`
		width: 110px;
		height: 48px;
		background-image: url(${`${process.env.S_HOST}/v1/web/main/select_bubble.png`});
		background-size: cover;
		font-size: 17px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: normal;
		text-align: center;
		padding-top: 8px;
		position: absolute;
		top: 0;
		transition: 0.2s;
		transition-timing-function: ease-in;
		left: ${(props) => props.selected * 116 || 0}px;
	`,
}
const 통합검색_찾아보기_버튼 = styled(Touchable)`
	z-index: 99;
	position: absolute;
	height: 60px;
	bottom: 0px;
	right: 0px;
	width: 66px;
	height: 60px;
	border: 0;
	text-align: center;
	justify-content: center;
	border-radius: 0 8px 8px 0;
	padding: 13px 18px 15px 20px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`

const 통합검색_찾아보기_버튼_이미지 = styled.img`
	width: 28px;
	height: 32px;
	object-fit: cover;
`
const SearchInput = styled.input`
	height: 60px;
	font-size: 16px;
	border-width: 0;
	background-color: #f2f2f2;
	line-height: 1.5;
	font-family: SpoqaHanSans;
	&::placeholder {
		color: #a6a6a6;
	}
`

const 중앙_안내_영역 = {
	전체영역: styled(View)`
		width: 100%;
		padding: 40px 0 27px;
		background: white;
		overflow: hidden;
	`,
	가운데영역: styled(View)`
		margin: auto;
		flex-direction: row;
	`,
	광고배너: styled(View)`
		width: 260px;

		display: block;
		overflow: hidden;
		white-space: nowrap;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	`,
	뉴스영역: styled(View)`
		margin-left: 30px;
		width: 260px;

		display: block;
		overflow: hidden;
		white-space: nowrap;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	`,
	공지영역: styled(View)`
		width: 260px;
		margin-left: 30px;
		display: block;

		overflow: hidden;
		white-space: nowrap;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;

		color: black;
	`,
	advertisement: styled(View)`
		width: 260px;
		margin-left: 30px;
		display: block;

		overflow: hidden;
		white-space: nowrap;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;

		color: black;
	`,
	목록_한개씩_레이아웃: styled(Touchable)`
		height: 32px;
		width: "100%";
		vertical-align: middle;
		justify-content: center;
		background-color: #ffffff;
		&:hover {
			background-color: #f6f6f6;
		}
	`,
	더보기_영역: styled(View)`
		width: 60px;
		height: 30px;
		border-radius: 4px;
		background-color: #eeeeee;
		padding-top: 9px;
		text-align: center;
		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
	`,
	더보기_버튼: styled(BaseText)`
		width: 100%;
		height: 100%;
		font-size: 12px;
		color: #757575;
	`,
}

const 앱스토어_이동_영역 = {
	배경영역: styled(View)`
		background: url(${`${process.env.S_HOST}/v1/web/main/downLoad_phone_new.png`}) no-repeat 109px 0;
		width: 890px;
		height: 280px;
		padding-left: 366px;
		margin: 0 auto;
		box-sizing: border-box;
		background-size: contain;
	`,
	아이폰_바로가기: styled(View)`
		width: 100%;
		height: 100%;
		:hover {
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 4px;
		}
	`,
	안드로이드_바로가기: styled(View)`
		width: 100%;
		height: 100%;
		:hover {
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 4px;
		}
	`,
	연결_버튼_영역: styled(View)`
		display: flex;
		flex-direction: row;
		width: 400px;
		justify-content: space-between;
	`,
}
